// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  APIEndpoint: 'https://tk.yaadiemarket.com',
  // APIEndpoint: 'http://localhost:9090',
  // APIEndpoint: 'http://0.0.0.0:5000',
  apiKey: 'AIzaSyDEgycgZAIT4qsbW_R4Z70E2fenvC3xC6Q',
  siteKey: '6LcNLxIeAAAAANHUUyD87GXpsBpxo8V5PchHr0tW'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
